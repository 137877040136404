<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="상담 내용" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline>
                <c-btn v-if="updateMode && editable" label="LBLREMOVE" icon="remove" @btnClicked="removeConsult" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveConsult"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  v-if="isSuspect"
                  label="부서/유소견자"
                  :editable="editable"
                  :disabled="true"
                  name="suspectUserName"
                  v-model="popupParam.suspectUserName">
                </c-text>
                <c-field
                  v-else-if="!isSuspect"
                  type="dept_user"
                  :editable="editable"
                  :data="data"
                  deptValue="normalDeptCd"
                  label="부서/일반사람"
                  name="normalUserId"
                  v-model="data.normalUserId">
                </c-field>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-select
                  required
                  :editable="editable"
                  codeGroupCd="SUSPECT_CONSULT_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="consultTypeCd"
                  label="상담구분"
                  v-model="data.consultTypeCd"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  required
                  :editable="editable"
                  label="상담일"
                  name="consultDate"
                  default="today"
                  v-model="data.consultDate"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  label="상담자"
                  :editable="editable"
                  name="counselorName"
                  v-model="data.counselorName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-text
                  required
                  :editable="editable"
                  label="상담내용"
                  name="consultContent"
                  v-model="data.consultContent">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  label="증상"
                  :editable="editable"
                  name="symptom"
                  :rows="2"
                  v-model="data.symptom">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  label="과거력"
                  :editable="editable"
                  name="diseasePast"
                  :rows="2"
                  v-model="data.diseasePast">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  label="현 병력"
                  :editable="editable"
                  :rows="2"
                  name="diseaseCurrent"
                  v-model="data.diseaseCurrent">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  label="상담결과 및 특이사항"
                  :editable="editable"
                  :rows="2"
                  name="remark"
                  v-model="data.remark">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="측정" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-3">
                <c-text
                  :editable="editable"
                  suffix="mg/dL"
                  label="혈당"
                  name="bidSugValue"
                  v-model="data.bidSugValue">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  suffix="mg/dL"
                  label="콜레스테롤"
                  name="cholesterolValue"
                  v-model="data.cholesterolValue">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  suffix="mmHg"
                  label="혈압-수축기"
                  name="bidPressContValue"
                  v-model="data.bidPressContValue">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  suffix="mmHg"
                  label="혈압-이완기"
                  type="number"
                  name="bidPressReleValue"
                  v-model="data.bidPressReleValue">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="table"
            title="투약 목록"
            tableId="table"
            :columns="grid.columns"
            :data="data.medicineList"
            :merge="grid.merge"
            :isFullScreen="false"
            :columnSetting="false"
            :filtering="false"
            :usePaging="false"
            :hideBottom="true"
            :isExcelDown="false"
            :editable="editable"
            gridHeight="280px"
            selection="multiple"
            rowKey="suspectMedicineId"
          >
            <!-- 버튼 영역 -->
            <template slot="table-button">
              <div>
                <q-btn-group outline>
                  <c-btn v-if="editable" label="LBLADD" icon="add" @btnClicked="addrow" />
                  <c-btn v-if="editable" label="LBLEXCEPT" icon="remove" @btnClicked="removerow" />
                </q-btn-group>
              </div>
            </template>
          </c-table>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'consult-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        suspectConsultId: '',
        suspectId: '',
        suspectFlag: '',
        suspectUserName: '',
        heaBrainCardiovascularSurveyId: '',
      }),
    },
  },
  data() {
    return {
      editable: false,
      data: {
        suspectConsultId: '',
        suspectId: '',
        heaBrainCardiovascularSurveyId: '',
        suspectUserId: '',
        consultContent: '',
        consultDate: '',
        diseasePast: '',
        diseaseCurrent: '',
        symptom: '',
        remark: '',
        consultTypeCd: null,
        counselorName: '',
        bidSugValue: '',
        cholesterolValue: '',
        bidPressContValue: '',
        bidPressReleValue: '',
        regUserId: '',
        chgUserId: '',
        normalDeptCd: '',
        normalUserId: '',
        suspectFlag: '',

        medicineList: [],
        deleteMedicineList: [],
      },
      grid: {
        columns: [
          {
            required: true,
            name: 'medicineName',
            field: 'medicineName',
            label: '약품명',
            style: 'width:170px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'medicineRecentCount',
            field: 'medicineRecentCount',
            label: '현재 재고량',
            style: 'width:100px',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'medicineCount',
            field: 'medicineCount',
            label: '투약수량',
            style: 'width:150px',
            align: 'right',
            type: 'number',
            sortable: false,
          },
          {
            name: 'unitName',
            field: 'unitName',
            label: 'LBLUNIT',
            style: 'width:80px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'medicinePeriod',
            field: 'medicinePeriod',
            label: '투약기간',
            style: 'width:200px',
            align: 'left',
            range: true,
            type: 'date',
            sortable: false,
          },
          {
            name: 'howToUse',
            field: 'howToUse',
            label: '효능/투약방법',
            style: 'width:150px',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: 'LBLREMARK',
            style: 'width:250px',
            align: 'left',
            type: 'text',
            sortable: false,
          },
        ],
        data: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      updateMode: false,
      getUrl: '',
      saveUrl: 'transactionConfig.hea.checkup.suspect.consult.insert.url;',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      isSave: false,
      mappingType: 'POST',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isSuspect() {
      return this.popupParam.suspectFlag === 'Y'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.hea.checkup.suspect.consult.get.url;
      this.insertUrl = transactionConfig.hea.checkup.suspect.consult.insert.url;
      this.updateUrl = transactionConfig.hea.checkup.suspect.consult.update.url;
      this.deleteUrl = transactionConfig.hea.checkup.suspect.consult.delete.url;

      if (this.popupParam.suspectFlag === 'Y') {
        this.$set(this.data, 'consultTypeCd', 'SCT0000002')
      } else {
        this.$set(this.data, 'consultTypeCd', 'SCT0000001')
      }
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.suspectConsultId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.suspectConsultId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = this.$_.clone(_result.data);

          this.$_.forEach(_result.data.medicineList, _item => {
            if (_item.medicineStartDate && _item.medicineEndDate) {
              _item.medicinePeriod = [_item.medicineStartDate, _item.medicineEndDate]
            }
          })
          this.updateMode = true;
        },);
      } else {
        if (this.popupParam.heaBrainCardiovascularSurveyId) {
          this.$set(this.data, 'heaBrainCardiovascularSurveyId', this.popupParam.heaBrainCardiovascularSurveyId)
        }
      }
    },
    saveConsult() {
      if (this.popupParam.suspectConsultId) {
        this.mappingType = 'PUT';
        this.saveUrl = this.updateUrl;
      } else {
        this.mappingType = 'POST';
        this.saveUrl = this.insertUrl;
        this.data.suspectId = this.popupParam.suspectId
        this.data.suspectFlag = this.popupParam.suspectFlag;
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error 
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.userId;
              this.data.chgUserId = this.$store.getters.userId;

              this.$_.forEach(this.data.medicineList, _item => {
                _item.medicineRecentCount = Number(_item.medicineRecentCount) + Number(_item.beforeRecentCount) - Number(_item.medicineCount);
                if (_item.medicinePeriod && _item.medicinePeriod.length > 0) {
                  _item.medicineStartDate = _item.medicinePeriod[0]
                  _item.medicineEndDate = _item.medicinePeriod[1]
                }
              })
              this.$_.forEach(this.data.deleteMedicineList, _item => {
                _item.medicineRecentCount = Number(_item.medicineRecentCount) + Number(_item.medicineCount);

              })

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.suspectConsultId = result.data
      this.getDetail();
    },
    removeConsult() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.suspectConsultId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          })
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    addrow() {
      this.popupOptions.title = "약품 선택"; // 약품 선택
      this.popupOptions.param = {
        type: "multiple",
        plantCd: this.data.plantCd,
      };
      this.popupOptions.target = () =>
        import(`${"./medicineManagePop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeMedicinePopup;
      // this.data.medicineList.push({
      //   suspectConsultId: this.popupParam.suspectConsultId,
      //   suspectMedicineId: uid(),
      //   medicineId: '',
      //   medicineStartDate: '',
      //   medicineEndDate: '',
      //   medicinePeriod: [],
      //   medicineCount: '',
      //   howToUse: '',
      //   remarks: '',
      //   regUserId: this.$store.getters.user.userId,
      //   chgUserId: this.$store.getters.user.userId,
      // })
    },
    closeMedicinePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          if (this.$_.findIndex(this.data.medicineList, { medicineId: _item.medicineId }) === -1) {
            this.data.medicineList.push({
              suspectConsultId: this.popupParam.suspectConsultId,
              suspectMedicineId: uid(),
              medicineName: _item.medicineName,
              medicineRecentCount: _item.medicineRecentCount,
              medicineId: _item.medicineId,
              beforeRecentCount: 0,
              medicineStartDate: '',
              medicineEndDate: '',
              medicinePeriod: [],
              medicineCount: '',
              unitName: _item.unitName,
              howToUse: '',
              // howToUse: _item.efficacy + '/' + _item.howToUse,
              remarks: '',
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId,
              editFlag: 'C',
            })
          }
        })
      }
    },
    removerow() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.data.deleteMedicineList) {
          this.data.deleteMedicineList = []
        }
        this.$_.forEach(selectData, item => {
          if (this.$_.findIndex(this.data.deleteMedicineList, { buyRequestDetailId: item.buyRequestDetailId }) === -1
            && item.editFlag !== 'C') {
              this.data.deleteMedicineList.push(item)
          }
          this.data.medicineList = this.$_.reject(this.data.medicineList, item)
        });
        this.$refs['table'].$refs['compo-table'].clearSelection();
      }
    },
  }
};
</script>